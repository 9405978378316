import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionsTable } from 'components/actions-table'
import { FocusSkillCards } from 'components/focus-skill-cards'
import { useStore } from 'store/context'

type StoreProgressDashboardProps = {
  userId: string
}

export const StoreProgressDashboard = observer(
  (props: StoreProgressDashboardProps) => {
    const { userId } = props

    const { actions, userSkills } = useStore()

    const source = 'profile-page'

    const fetchActions = React.useCallback(async () => {
      await actions.fetchForUser(userId)
    }, [actions, userId])

    const fetchUserSkills = React.useCallback(async () => {
      await userSkills.fetchForUser(userId)
    }, [userId, userSkills])

    React.useEffect(() => {
      fetchActions()
      fetchUserSkills()
    }, [fetchActions, fetchUserSkills])

    return (
      <>
        {userSkills.focusedForUser(userId).length > 0 && (
          <FocusSkillCards userSkills={userSkills.focusedForUser(userId)} />
        )}
        {actions.incompleteWithPartialCompletedForUser(3, userId).length >
          0 && (
          <div className="pt-24">
            <>
              <div className="flex items-center justify-between pb-5">
                <h3 className="text-2xl">
                  Actions{' '}
                  <span className="text-gray-300">
                    {actions.incompleteForUser(userId).length}
                  </span>
                </h3>
              </div>
              <ActionsTable
                actions={actions.incompleteWithPartialCompletedForUser(
                  3,
                  userId
                )}
                focusSkillIds={userSkills.focusedSkillIdsForUser(userId)}
                source={source}
              />
            </>
          </div>
        )}
      </>
    )
  }
)
