import { deserialize } from '../../api/utils/deprecated_deserialize'
import * as React from 'react'
import { actionsSchema } from '../../contracts/action'
import { checkinSkillsSchema } from '../../contracts/checkin-skill'
import { FocusSkillsRow } from '.'
import { focusSkillWinsSchema } from '../../contracts/win'
import { frameworksSkillsSchema } from '../../contracts/frameworks-skill'
import { ProgressProvider } from 'components/progress-provider'
import { requirementsSchema } from '../../contracts/requirement'
import { skillsSchema } from '../../contracts/skill'

type RailsFocusSkillsRowProps = {
  actions: unknown
  checkinSkills: unknown
  focusSkills: unknown
  frameworksSkills: unknown
  requirements: unknown
  wins: unknown
}

export const RailsFocusSkillsRow: React.VFC<RailsFocusSkillsRowProps> = (
  props
) => {
  const {
    actions: initialActions,
    checkinSkills: initialCheckinSkills,
    focusSkills: initialFocusSkills,
    frameworksSkills: initialFrameworksSkills,
    requirements: initialRequirements,
    wins: initialWins,
  } = props

  const {
    actions,
    checkinSkills,
    focusSkillIds,
    focusSkills,
    frameworksSkills,
    requirements,
    wins,
  } = React.useMemo(() => {
    const { data: actionsData } = deserialize(initialActions)
    const actions = actionsSchema.parse(actionsData)

    const { data: checkinSkillsData } = deserialize(initialCheckinSkills)
    const checkinSkills = checkinSkillsSchema.parse(checkinSkillsData)

    const { data: focusSkillsData } = deserialize(initialFocusSkills)
    const focusSkills = skillsSchema.parse(focusSkillsData)

    const { data: frameworksSkillsData } = deserialize(initialFrameworksSkills)
    const frameworksSkills = frameworksSkillsSchema.parse(frameworksSkillsData)

    const { data: requirementsData } = deserialize(initialRequirements)
    const requirements = requirementsSchema.parse(requirementsData)

    const { data: winsData } = deserialize(initialWins)
    const wins = focusSkillWinsSchema.parse(winsData)

    const focusSkillIds = focusSkills.map((focusSkill) => focusSkill.id)

    return {
      actions,
      checkinSkills,
      focusSkillIds,
      focusSkills,
      frameworksSkills,
      requirements,
      wins,
    }
  }, [
    initialActions,
    initialCheckinSkills,
    initialFocusSkills,
    initialFrameworksSkills,
    initialRequirements,
    initialWins,
  ])

  return (
    <ProgressProvider
      checkinSkills={checkinSkills}
      initialActions={actions}
      initialFocusSkillIds={focusSkillIds}
      requirements={requirements}
      skillId={undefined}
      source="profile-page"
    >
      <FocusSkillsRow
        frameworksSkills={frameworksSkills}
        initialFocusSkills={focusSkills}
        wins={wins}
      />
    </ProgressProvider>
  )
}
