import { FocusSkillStar } from 'components/focus-skill-star'
import { SkillActivityFeed } from 'components/skill-activity-feed'
import { SkillModalButton } from 'components/skill-modal/skill-modal-button'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useStore } from 'store/context'
import { ActionsSection } from './actions-section'
import { SkillLevelSection } from './skill-level-section'
import { store } from 'store/index'

type UserSkillPageProps = {
  skillId: string
  skillVariantId: string
  userId: string
}

export const UserSkillPage = observer((props: UserSkillPageProps) => {
  const { skillId, skillVariantId, userId } = props

  const source = 'skill-page'

  const { actions, currentUser, outcomes, skillLevels, userSkills } = useStore()

  React.useEffect(() => {
    async function fetchActions() {
      await actions.fetchAll({
        filter: { skill_id: skillId, user_id: userId },
        include: ['skills', 'user'],
      })
    }

    fetchActions()
  }, [actions, skillId, userId])

  React.useEffect(() => {
    async function fetchUserSkills() {
      await userSkills.fetchOne(
        skillId,
        { include: ['skill', 'user'] },
        { namespace: ['users', userId] }
      )
    }

    fetchUserSkills()
  }, [skillId, userId, userSkills])

  const userSkill = userSkills.forSkillAndUser(skillId, userId)

  React.useEffect(() => {
    async function fetchSkillLevel() {
      if (userSkill?.positionRequirementLevel) {
        await skillLevels.fetchAll({
          filter: {
            level: userSkill.positionRequirementLevel,
            skill_variant_id: skillVariantId,
          },
          include: ['outcomes'],
        })
      }
    }

    fetchSkillLevel()
  }, [userSkill, skillId, skillLevels, skillVariantId])

  if (!userSkill) return null

  const skill = userSkill.skill

  const skillVariant =
    store.skillVariants.byId(skillVariantId) || skill.defaultVariant

  const user = userSkill.user

  if (!skillVariant) return null

  const skillLevel = userSkill.positionRequirementLevel
    ? skillLevels.forLevelAndSkillVariant(
        userSkill.positionRequirementLevel,
        skillVariantId
      )
    : undefined

  const outcomesForSkillLevel = userSkill.positionRequirementLevel
    ? outcomes.forLevelAndSkillVariant(
        userSkill.positionRequirementLevel,
        skillVariant.id
      )
    : []

  const isCurrentUser = currentUser === user

  return (
    <section className="flex flex-col lg:flex-row bg-white min-h-full">
      <div className="h-full lg:min-w-[290px] lg:max-w-[290px]">
        <div className="border-0 border-solid border-r border-gray-100 flex flex-col pt-8 px-[15px] md:p-4 md:pt-14 lg:fixed lg:h-screen lg:overflow-auto lg:w-[290px]">
          <div className="my-4 w-full text-2xl font-bold">
            <SkillModalButton
              className="p-0 text-gray-900 cursor-pointer"
              skillId={skillId}
              skillVariantId={skillVariant.id}
              source={source}
            >
              {skill.name}
            </SkillModalButton>{' '}
            <div className="inline-flex space-x-1">
              {userSkill.positionRequirementLevel && (
                <div className="text-gray-600">
                  L{userSkill.positionRequirementLevel}
                </div>
              )}
              <div className="mt-1">
                <FocusSkillStar
                  editable={isCurrentUser}
                  size={22}
                  source={source}
                  userSkill={userSkill}
                />
              </div>
            </div>
          </div>
          <div className="my-4 w-full">
            <SkillModalButton
              className="p-0 text-theme-40 cursor-pointer"
              skillId={skillId}
              skillVariantId={skillVariant.id}
              source={source}
            >
              More levels
            </SkillModalButton>
          </div>
          {skillLevel && (
            <SkillLevelSection
              skillLevel={skillLevel}
              outcomes={outcomesForSkillLevel}
            />
          )}
          <ActionsSection
            actions={actions.forSkillAndUser(skillId, userId)}
            focusSkillIds={userSkills.focusedSkillIdsForUser(userId)}
            skillId={skillId}
            source={source}
            user={user}
          />
        </div>
      </div>

      <div className="container-md mt-10 md:px-14 max-w-[740px]">
        <SkillActivityFeed skillId={skillId} userId={userId} />
      </div>
    </section>
  )
})
