import { useModalContext } from 'src/design-system'
import * as React from 'react'
import { Action } from '../../contracts/action'
import { ActionsService } from '../../services/actions-service'
import { CheckinSkill } from '../../contracts/checkin-skill'
import { ProgressContext } from './progress-context'
import { Requirement } from '../../contracts/requirement'
import { useApiClient } from '../../utils/use-api-client'
import { ACTION_COMPLETED_MODAL_ID } from 'components/action-completed-modal/utils'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'

export type ProgressProviderProps = {
  checkinSkills: CheckinSkill[]
  children: React.ReactNode
  initialActions: Action[]
  initialFocusSkillIds: string[]
  requirements: Requirement[]
  skillId: string | undefined
  source: string
}

export type OpenFormModalProps = {
  resourceId?: string
  resourceType?: 'Action' | 'Win' | 'Note' | 'FeedbackRequest'
  defaultSkillId?: string
  notes?: string
  source: string
  userId?: string
}

export const ProgressProvider: React.VFC<ProgressProviderProps> = (props) => {
  const {
    checkinSkills,
    children,
    initialActions,
    initialFocusSkillIds,
    requirements,
    skillId,
    source,
  } = props

  const [actions, setActions] = React.useState(initialActions)

  const [focusSkillIds, setFocusSkillIds] = React.useState(initialFocusSkillIds)

  const { openModal } = useModalContext()

  const openCompleteModal = (action: Action) => {
    openModal(ACTION_COMPLETED_MODAL_ID, { action })
  }

  const openFormModal = (props: OpenFormModalProps | CreateFormProps) => {
    openModal<OpenFormModalProps | CreateFormProps>(CREATE_FORM_MODAL_ID, props)
  }

  const client = useApiClient()
  const service = React.useMemo(() => new ActionsService(client), [client])

  return (
    <ProgressContext.Provider
      value={{
        actions,
        setActions,
        checkinSkills,
        focusSkillIds,
        setFocusSkillIds,
        openCompleteModal,
        openFormModal,
        requirements,
        service,
        skillId,
        source,
      }}
    >
      {children}
    </ProgressContext.Provider>
  )
}
