import { Action } from 'store/modules/actions'
import { User } from 'store/modules/users'

export class CheckinActionsSectionVm {
  constructor(
    private actions: Action[],
    private currentUser: User | null,
    private userId: string,
    private userName: string
  ) {}

  get incompleteCount() {
    return this.actions.filter((action) => !action.completed).length
  }

  get subtitle() {
    const word =
      this.currentUser?.id === this.userId ? 'your' : `${this.userName}'s`

    return `Since ${word} last Check-in`
  }
}
