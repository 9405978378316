import { z } from 'zod'
import { skillSchema } from './skill'

export const checkinSkillSchema = z.object({
  finalLevel: z.number(),
  id: z.string(),
  skill: skillSchema,
})

export const checkinSkillsSchema = z.array(checkinSkillSchema)

export type CheckinSkill = z.infer<typeof checkinSkillSchema>
