import { z } from 'zod'

export const skillSchema = z.object({
  id: z.string(),
  name: z.string(),
  slug: z.string().nullish(),
})

export const skillsSchema = z.array(skillSchema)

export type Skill = z.infer<typeof skillSchema>
