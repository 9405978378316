import { z } from 'zod'
import { categorySchema } from './category'
import { skillSchema } from './skill'

export const frameworksSkillSchema = z.object({
  category: categorySchema.nullable(),
  id: z.string(),
  skill: skillSchema,
})

export const frameworksSkillsSchema = z.array(frameworksSkillSchema)

export type FrameworksSkill = z.infer<typeof frameworksSkillSchema>
