import { Action } from '../../contracts/action'
import { CheckinSkill } from '../../contracts/checkin-skill'
import { FocusSkillWin } from '../../contracts/win'
import { FrameworksSkill } from '../../contracts/frameworks-skill'
import { openModal } from '../../utils/open-modal'
import { Requirement } from '../../contracts/requirement'
import { Skill } from '../../contracts/skill'
import { trackEvent } from '../../services/event-tracker'
import { UserSkill } from 'store/modules/user_skills'

export type ActionStatusIcon = 'fire' | 'half-circle'

type Dependencies = {
  openModal: typeof openModal
}

const defaultDependencies = {
  openModal,
}

export class FocusSkillsRowVm {
  constructor(
    private actions: Action[],
    private checkinSkills: CheckinSkill[],
    public focusSkills: Skill[],
    private setFocusSkills: (focusSkills: Skill[]) => void,
    private frameworksSkills: FrameworksSkill[],
    private requirements: Requirement[],
    private skills: Skill[],
    public suggestedSkills: Skill[],
    private setSuggestedSkills: (suggestedSkill: Skill[]) => void,
    private wins: FocusSkillWin[],
    private deps: Dependencies = defaultDependencies
  ) {}

  public static sortSkills(
    checkinSkills: CheckinSkill[],
    requirements: Requirement[]
  ) {
    const sortedRequirements = requirements.sort((a, b) => {
      const aCheckinSkill = checkinSkills.find(
        (checkinSkill) => checkinSkill.skill.id === a.skill.id
      )
      const bCheckinSkill = checkinSkills.find(
        (checkinSkill) => checkinSkill.skill.id === b.skill.id
      )

      const aDifference = aCheckinSkill
        ? a.level - aCheckinSkill.finalLevel
        : -0.5
      const bDifference = bCheckinSkill
        ? b.level - bCheckinSkill.finalLevel
        : -0.5

      return bDifference - aDifference
    })

    return sortedRequirements.map((requirement) => requirement.skill)
  }

  readonly maxNumberOfFocusSkills = 3

  get focusSkillCount() {
    return this.focusSkills.length
  }

  get showFocusSkillsRow() {
    return this.showFocusSkills || this.showSuggestedSkills
  }

  get showFocusSkills() {
    return this.focusSkillCount > 0
  }

  get showSuggestedSkills() {
    return this.focusSkillCount < 3 && this.suggestedSkills.length > 0
  }

  actionStatusIconType(skill: Skill): ActionStatusIcon {
    return this.actionsAllCompleted(skill) ? 'fire' : 'half-circle'
  }

  cardSubtitle(selected: boolean, skill: Skill) {
    if (!selected) return 'Suggested focus'

    const category = this.frameworksSkills.find(
      (frameworksSkill) => frameworksSkill.skill.id == skill.id
    )?.category

    return category?.name || 'Uncategorised'
  }

  cardWinCount(skill: Skill) {
    switch (this.winCount(skill)) {
      case 0:
        return 'No Wins'
      case 1:
        return '1 Win'
      default:
        return `${this.winCount(skill)} Wins`
    }
  }

  checkinSkillLevel(skill: Skill) {
    const checkinSkill = this.checkinSkills.find(
      (checkinSkill) => checkinSkill.skill.id === skill.id
    )

    return checkinSkill ? checkinSkill.finalLevel : null
  }

  completedActionStatus(skill: Skill) {
    return `${this.completedActionCount(skill)}/${this.actionCount(skill)}`
  }

  openWinModal(skill: Skill) {
    this.deps.openModal(
      `/users/me/wins/received?filterrific[skills_filter]=${skill.id}&modal=true`
    )
  }

  showAddAction(skill: Skill) {
    return this.actionCount(skill) === 0
  }

  showCardWinLink(selected: boolean, skill: Skill) {
    return selected && this.winCount(skill) > 0
  }

  showCardWinText(selected: boolean, skill: Skill) {
    return selected && this.winCount(skill) === 0
  }

  shuffleSuggestedSkill(skill: Skill) {
    trackEvent('$track_focus_skill_shuffled', {
      skillId: skill.id,
      skillName: skill.name,
    })

    // Filter skills that are not already focused, suggested or the original suggested skill
    const remainingSuggestedSkills = [...this.skills].filter(
      (suggestedSkill) => {
        return (
          !this.focusAndSuggestedSkillIds.includes(suggestedSkill.id) &&
          suggestedSkill.id != skill.id
        )
      }
    )

    // Pick a random suggested skill
    const newSuggestedSkill =
      remainingSuggestedSkills[
        Math.floor(Math.random() * remainingSuggestedSkills.length)
      ]

    // Find index of the original suggested skill
    const index = this.suggestedSkills.indexOf(skill)

    if (~index) {
      // Replace original suggested skill with the new one
      const newSuggestedSkills = [...this.suggestedSkills]
      newSuggestedSkills[index] = newSuggestedSkill
      this.setSuggestedSkills(newSuggestedSkills)
    }
  }

  skillRequirementLevel(skill: Skill) {
    const requirement = this.requirements.find(
      (requirement) => requirement.skill.id === skill.id
    )

    return requirement ? requirement.level : null
  }

  addSkillToFocusSkills(skillId: string) {
    if (!this.skillIds(this.focusSkills).includes(skillId)) {
      const skill = this.skills.find((skill) => skill.id === skillId)

      if (skill) {
        const newFocusSkills = [...this.focusSkills, skill]
        this.setFocusSkills(newFocusSkills)

        const newSuggestedSkills = this.skillsWithoutSkill(
          skillId,
          this.suggestedSkills
        )

        this.setSuggestedSkills(
          newSuggestedSkills.slice(
            0,
            this.maxNumberOfFocusSkills - newFocusSkills.length
          )
        )
      }
    }
  }

  removeSkillFromFocusSkills(skillId: string) {
    const newFocusSkills = this.skillsWithoutSkill(skillId, this.focusSkills)
    this.setFocusSkills(newFocusSkills)

    if (this.maxNumberOfFocusSkills - newFocusSkills.length > 0) {
      const newSuggestedSkill = this.skills.find((skill) => {
        const ids = this.skillIds(newFocusSkills).concat(
          this.skillIds(this.suggestedSkills)
        )

        return !ids.includes(skill.id)
      })

      if (newSuggestedSkill) {
        this.setSuggestedSkills([...this.suggestedSkills, newSuggestedSkill])
      }
    }
  }

  async onClickSelectSkill(userSkill: UserSkill, source: string) {
    await userSkill.update({ focused: true }, { source })

    const focusSkillEvent = new CustomEvent('focusskill:added', {
      detail: { id: userSkill.skill.id },
    })

    document.dispatchEvent(focusSkillEvent)
  }

  private actionCount(skill: Skill) {
    return this.actions.filter((action) =>
      action.skills.map((skill) => skill.id).includes(skill.id)
    ).length
  }

  private actionsAllCompleted(skill: Skill) {
    const actionCount = this.actionCount(skill)
    if (actionCount === 0) return false

    return actionCount === this.completedActionCount(skill)
  }

  private completedActionCount(skill: Skill) {
    return this.actions.filter(
      (action) =>
        action.completed &&
        action.skills.map((skill) => skill.id).includes(skill.id)
    ).length
  }

  private skillIds(skills: Skill[]) {
    return skills.map((skill) => skill.id)
  }

  private skillsWithoutSkill(skillId: string, skills: Skill[]) {
    return [...skills].filter((skill) => skill.id !== skillId)
  }

  private winCount(skill: Skill) {
    return this.wins.filter((win) =>
      win.skills.map((skill) => skill.id).includes(skill.id)
    ).length
  }

  private get focusAndSuggestedSkillIds() {
    return this.skillIds(this.focusSkills).concat(
      this.skillIds(this.suggestedSkills)
    )
  }
}
