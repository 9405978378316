import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Action } from 'store/modules/actions'
import { ActionsEmptyState } from 'components/actions-empty-state'
import { ActionsTable } from 'components/actions-table'
import { CreateFormModalButton } from 'components/create-form-modal'
import { User } from 'store/modules/users'

type ActionsSectionProps = {
  actions: Action[]
  focusSkillIds: string[]
  skillId: string
  source: string
  user: User
}

export const ActionsSection = observer((props: ActionsSectionProps) => {
  const { actions, focusSkillIds, skillId, source, user } = props

  const incompleteActionCount = actions.filter(
    (action) => !action.completed
  ).length

  return (
    <div className="mb-3 mt-14 w-full">
      {actions.length > 0 && (
        <>
          <div className="flex items-center justify-between pb-1">
            <h3 className="text-base">
              {'Related Actions '}
              <span className="text-gray-300">{incompleteActionCount}</span>
            </h3>
          </div>
          <ActionsTable
            actions={actions}
            editable
            focusSkillIds={focusSkillIds}
            source={source}
          />
          <CreateFormModalButton
            className="btn max-w-none w-full"
            initialSkillIds={[skillId]}
            label="Add Action"
            source={source}
            tabs={['action']}
            user={user}
            variant="outline"
          />
        </>
      )}
      {actions.length === 0 && (
        <ActionsEmptyState
          skillId={skillId}
          source={source}
          user={user}
          variant="sidebar"
        />
      )}
    </div>
  )
})
