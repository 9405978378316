import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { FocusSkillCard } from './focus-skill-card'
import { UserSkill } from 'store/modules/user_skills'

type FocusSkillCardsProps = {
  userSkills: UserSkill[]
}

export const FocusSkillCards = observer((props: FocusSkillCardsProps) => {
  const { userSkills } = props

  if (userSkills.length === 0) return null

  return (
    <div>
      <h3 className="mr-2 mb-4">
        Focus Skills{' '}
        <span className="text-gray-300">{userSkills.length}/3</span>
      </h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {userSkills.map((userSkill) => (
          <FocusSkillCard key={userSkill.id} userSkill={userSkill} />
        ))}
      </div>
    </div>
  )
})
