import { z } from 'zod'
import { skillSchema } from './skill'

export const actionSchema = z.object({
  id: z.string(),
  completed: z.boolean(),
  content: z.string(),
  skills: z.array(skillSchema),
  dueDate: z.preprocess(
    (a) => (a ? new Date(z.string().parse(a)) : null),
    z.date().nullable()
  ),
})

export const actionsSchema = z.array(actionSchema)

export type Action = z.infer<typeof actionSchema>
