import { z } from 'zod'

const winCategoryTitle = z.enum(['Note', 'Win'])

export type WinCategoryTitle = z.infer<typeof winCategoryTitle>

export const winCategorySchema = z.object({
  id: z.string().transform((v) => parseInt(v)),
  title: winCategoryTitle,
})

export type WinCategory = z.infer<typeof winCategorySchema>
