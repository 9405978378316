import * as React from 'react'
import { FocusSkillsRowVm } from './focus-skills-row-vm'
import { FocusSkillWin } from '../../contracts/win'
import { FrameworksSkill } from '../../contracts/frameworks-skill'
import { Skill } from '../../contracts/skill'
import { useProgress } from 'components/progress-provider'

type UseFocusSkillsRowProps = {
  frameworksSkills: FrameworksSkill[]
  initialFocusSkills: Skill[]
  wins: FocusSkillWin[]
}

export const useFocusSkillsRowVm = (props: UseFocusSkillsRowProps) => {
  const { frameworksSkills, initialFocusSkills, wins } = props

  const { actions, checkinSkills, requirements } = useProgress()

  const skills = FocusSkillsRowVm.sortSkills(checkinSkills, requirements)

  const initialSuggestedSkills = () => {
    const focusSkillIds = initialFocusSkills.map((focusSkill) => focusSkill.id)

    return skills
      .filter((skill) => !focusSkillIds.includes(skill.id))
      .slice(0, 3 - initialFocusSkills.length)
  }

  const [focusSkills, setFocusSkills] = React.useState(initialFocusSkills)

  const [suggestedSkills, setSuggestedSkills] = React.useState(
    initialSuggestedSkills()
  )

  const vm = React.useMemo(() => {
    return new FocusSkillsRowVm(
      actions,
      checkinSkills,
      focusSkills,
      setFocusSkills,
      frameworksSkills,
      requirements,
      skills,
      suggestedSkills,
      setSuggestedSkills,
      wins
    )
  }, [
    actions,
    checkinSkills,
    focusSkills,
    setFocusSkills,
    frameworksSkills,
    requirements,
    skills,
    suggestedSkills,
    setSuggestedSkills,
    wins,
  ])

  return vm
}
