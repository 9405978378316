import { observer } from 'mobx-react-lite'
import { AvatarButton, Tooltip, useModalContext } from 'src/design-system'
import * as React from 'react'
import clip from 'text-clipper'
import cn from 'classnames'
import { Action } from 'store/modules/actions'
import { ACTION_COMPLETED_MODAL_ID } from 'components/action-completed-modal/utils'
import { ActionOverflow } from 'components/actions-table/action-overflow'
import { CheckIcon } from 'components/actions-table/check-icon'
import { CHECKIN_ACTIONS_SECTION_SOURCE } from 'components/checkin-actions-section/utils'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'
import { formatDate } from '../../utils/date-helpers'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { LoadingContent } from 'components/loading-content'
import { SensitiveContent } from 'components/sensitive-content'
import { SkillTags } from 'components/skill-tags'
import { throwConfettiOnClick } from '../../utils/throw-confetti'
import { useStore } from 'store/context'
import styles from 'components/actions-table/actions-table.module.scss'
import { ActivityFeedAvatars } from 'components/activity-feed/activity-feed-header/activity-feed-avatars'

type ActionPageItemProps = {
  action: Action
  characterLimit?: number
  editable: boolean
  focusSkillIds: string[]
  skillsCharacterLimit?: number
  source: string
}

export const ActionPageItem = observer((props: ActionPageItemProps) => {
  const {
    action,
    characterLimit,
    editable,
    focusSkillIds,
    skillsCharacterLimit,
    source,
  } = props

  const { openModal } = useModalContext()
  const store = useStore()
  const [loading, setLoading] = React.useState(false)

  const onClickContent = React.useCallback(() => {
    openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
      resourceId: action.id,
      resourceType: 'Action',
      source,
      user: action.user,
      tabs: ['action'],
    })
  }, [action, openModal, source])

  const { nonNullCurrentUser } = store

  const onClickComplete = React.useCallback(
    async (e) => {
      setLoading(true)
      await store.actions.update(action.id, { completed: true }, { source })
      await store.activities.fetchAllWithAllIncludes(20)

      setLoading(false)
      throwConfettiOnClick(e)

      if (
        source !== CHECKIN_ACTIONS_SECTION_SOURCE &&
        nonNullCurrentUser.id === action.user.id
      ) {
        openModal(ACTION_COMPLETED_MODAL_ID, { action, source })
      }
    },
    [action, openModal, source, store, nonNullCurrentUser]
  )

  if (!action) return null

  const content = characterLimit
    ? clip(action.content, characterLimit, { html: true, maxLines: 3 })
    : action.content

  return (
    <li className="border p-4 rounded-sm mb-4 flex flex-col">
      <div className="flex gap-3 justify-between w-full">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row">
            <LoadingContent className="-mr-1 scale-75" loading={loading}>
              {!action.completed && (
                <Tooltip content="Complete action">
                  <button onClick={onClickComplete} type="button">
                    <CheckIcon completed={false} />
                  </button>
                </Tooltip>
              )}
              {action.completed && (
                <Tooltip content="Completed action">
                  <CheckIcon completed className="cursor-default" />
                </Tooltip>
              )}
            </LoadingContent>

            <SensitiveContent>
              <div
                className={cn(
                  'whitespace-normal cursor-pointer ml-2',
                  action.completed && 'opacity-50'
                )}
                onClick={onClickContent}
              >
                <HtmlContent className={cn(styles.actionText)}>
                  {content}
                </HtmlContent>
              </div>
            </SensitiveContent>
          </div>
          <div className="flex gap-1 justify-between w-full ml-6">
            <SkillTags
              characterLimit={skillsCharacterLimit}
              focusSkillIds={focusSkillIds}
              showLinks={editable}
              skills={action.skills}
            />
          </div>
          <div className="flex flex-row text-xs text-gray-600">
            {action.completed ? (
              <ActionFooterCompleted action={action} />
            ) : (
              <ActionFooter action={action} />
            )}
          </div>
        </div>
        {editable && (
          <div className="ml-auto">
            <ActionOverflow action={action} source={source} />
          </div>
        )}
      </div>
    </li>
  )
})

type ActionFooterProps = {
  action: Action
}

const ActionFooter = (props: ActionFooterProps) => {
  const { action } = props
  const store = useStore()

  return (
    <>
      <div className="flex flex-row gap-1 ">
        <ActivityFeedAvatars
          creators={[action.creator]}
          receivers={[action.user]}
          showReceivers={action.creator !== action.user}
          size="xs"
        />

        <span className="ml-1">
          Created by{' '}
          {action.creator.id == store.currentUser?.id
            ? 'you'
            : action.creator.fname}
        </span>
        <span className="mx-2">&middot;</span>
      </div>

      {action.createdAt && (
        <time dateTime={action.formattedCreatedDate}>
          {formatDate(action.createdAt)}
        </time>
      )}

      {action.dueDate && (
        <>
          <span className="mx-2">&middot; Due: </span>
          <time dateTime={action.formattedDueDate}>
            {formatDate(action.dueDate)}
            {action.overdue && <span className="sr-only">(overdue)</span>}
          </time>
        </>
      )}
    </>
  )
}

const ActionFooterCompleted = (props: ActionFooterProps) => {
  const { action } = props

  return (
    <>
      <div className="flex flex-row gap-1 ">
        <ActivityFeedAvatars
          creators={[action.user]}
          receivers={[]}
          showReceivers={false}
          size="xs"
        />

        <span className="mx-2">Completed &middot;</span>
      </div>

      {action.completedAt && (
        <time dateTime={action.formattedCompletedDate}>
          {formatDate(action.completedAt)}
        </time>
      )}
    </>
  )
}
