import * as React from 'react'
import { ProgressContext } from './progress-context'

export const useProgress = () => {
  const progressContext = React.useContext(ProgressContext)

  if (!progressContext) {
    throw new Error('useProgress must be used within a ProgressProvider')
  }

  return progressContext
}
