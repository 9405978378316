import { z } from 'zod'

export const userSchema = z.object({
  id: z.string().transform((v) => parseInt(v)),
  fname: z.string(),
  lname: z.string(),
  email: z.string().optional(),
  state: z.enum(['active', 'archived', 'not_invited']),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
  avatarUrl: z.string().optional().nullable(),
})

export const usersSchema = z.array(userSchema)

export type User = z.infer<typeof userSchema>
