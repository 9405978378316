import { z } from 'zod'
import { skillSchema } from './skill'

export const requirementSchema = z.object({
  id: z.string(),
  level: z.number(),
  skill: skillSchema,
})

export const requirementsSchema = z.array(requirementSchema)

export type Requirement = z.infer<typeof requirementSchema>
