import { deserialize } from '../api/utils/deprecated_deserialize'
import { errorToast } from '../utils/error-toast'
import { InternalApiClient } from './internal-api-client'
import { successToast } from '../utils/success-toast'

const baseUri = '/api/v1/internal/actions/'

export class ActionsService {
  constructor(private client: InternalApiClient) {}

  async complete(id: string, source: string) {
    try {
      const response = await this.client.put(`${baseUri}${id}`, {
        completed: true,
        source,
      })

      const { data } = deserialize(response)
      return data
    } catch (e) {
      console.error(e)
      errorToast()
      return false
    }
  }

  async destroy(id: string) {
    try {
      await this.client.delete(`${baseUri}${id}`)
      successToast('Action deleted!')
      return true
    } catch (e) {
      console.error(e)
      errorToast()
      return false
    }
  }
}
