import { ActionsPage } from '../src/pages/actions-page'
import { CheckinActionsSection } from 'components/checkin-actions-section'
import { RailsFocusSkillStar } from 'components/focus-skill-star'
import { forRails } from 'components/for-rails'
import { RailsFocusSkillsRow } from 'components/focus-skills-row/rails-focus-skills-row'
import { registerComponents } from '../src/utils/react/registry'
import { StoreProgressDashboard } from 'components/store-progress-dashboard'
import { UserSkillPage } from '../src/pages/user-skill-page'

registerComponents({
  ActionsPage: forRails(ActionsPage),
  CheckinActionsSection: forRails(CheckinActionsSection),
  FocusSkillStar: forRails(RailsFocusSkillStar),
  RailsFocusSkillsRow: forRails(RailsFocusSkillsRow),
  StoreProgressDashboard: forRails(StoreProgressDashboard),
  UserSkillPage: forRails(UserSkillPage),
})
