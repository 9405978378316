import * as React from 'react'
import { Action } from '../../contracts/action'
import { ActionsService } from '../../services/actions-service'
import { CheckinSkill } from '../../contracts/checkin-skill'
import { OpenFormModalProps } from './progress-provider'
import { Requirement } from '../../contracts/requirement'
import { CreateFormProps } from 'components/create-form'

type ProgressContext = {
  actions: Action[]
  setActions: (actions: Action[]) => void
  checkinSkills: CheckinSkill[]
  focusSkillIds: string[]
  setFocusSkillIds: (ids: string[]) => void
  openCompleteModal: (action: Action) => void
  openFormModal: (props: OpenFormModalProps | CreateFormProps) => void
  requirements: Requirement[]
  service: ActionsService
  skillId: string | undefined
  source: string
}

export const ProgressContext = React.createContext({} as ProgressContext)
