import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { store } from 'store/index'
import { useStore } from 'store/context'
import { Loader, TabbedContent } from 'src/design-system'
import * as SecondaryNav from 'components/secondary-nav'
import { ActionsPageTab } from './actions-page-tab'
import { ManagerUserSelect } from 'components/manager-user-select'
import { convertValueToUserIds } from 'components/manager-user-select/utils'

type ActionsPageProps = {
  userId: string
}

export type tabIdType = 'open' | 'completed' | 'all'

export const ActionsPage = observer((props: ActionsPageProps) => {
  const { actions, userSkills, users, nonNullCurrentUser } = useStore()

  const [managerSelectValue, setManagerSelectValue] = React.useState(
    nonNullCurrentUser.id
  )
  const userId = convertValueToUserIds(nonNullCurrentUser, managerSelectValue)

  const [loading, setLoading] = React.useState(true)

  const source = 'actions-page'

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchActions = async () => {
        await store.actions.fetchForUser(userId, {}, 1000)
      }

      const fetchUserSkills = async () => {
        // load user skills just for the current user
        if (userId.length === 1 && userId[0] == nonNullCurrentUser.id) {
          return await store.userSkills.fetchForUsers(userId)
        } else {
          return Promise.resolve
        }
      }

      await Promise.all([fetchActions(), fetchUserSkills()])
      setLoading(false)
    }

    fetchData()
  }, [userId])

  const [tabId, setTabId] = React.useState('open' as tabIdType)
  const focusSkillIds =
    userId.length === 1 && userId[0] == nonNullCurrentUser.id
      ? userSkills.focusedSkillIdsForUser(nonNullCurrentUser.id)
      : []

  if (!userId) return null

  const currentUserSelected =
    userId.length === 1 && userId[0] == nonNullCurrentUser.id
  return (
    <>
      {loading ? (
        <>
          <SecondaryNav.Root>
            <SecondaryNav.Header>Actions</SecondaryNav.Header>
          </SecondaryNav.Root>
          <main className="p-10 overflow-x-auto pb-20 flex-1">
            <Loader className="my-6" />
          </main>
        </>
      ) : (
        <TabbedContent.Root
          initialTabId={tabId}
          id="action-page"
          data-testid="action-page"
          className="flex-1 max-w-full"
        >
          <SecondaryNav.Root>
            <SecondaryNav.Header className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-row items-center gap-4">
                Actions
                {nonNullCurrentUser.isManager && (
                  <ManagerUserSelect
                    defaultValue={nonNullCurrentUser.id}
                    onChange={(value) =>
                      setManagerSelectValue(
                        value ? value : nonNullCurrentUser.id
                      )
                    }
                    user={nonNullCurrentUser}
                  />
                )}
                <TabbedContent.Tabs
                  variant="pill"
                  className="gap-1 overflow-y-auto"
                  data-element-id="create-form-tabs"
                >
                  <TabbedContent.Tab
                    id="all"
                    key="all"
                    onClick={() => setTabId('all')}
                  >
                    All
                  </TabbedContent.Tab>
                  <TabbedContent.Tab
                    id="open"
                    key="open"
                    onClick={() => setTabId('open')}
                  >
                    Open
                  </TabbedContent.Tab>
                  <TabbedContent.Tab
                    id="completed"
                    key="completed"
                    onClick={() => setTabId('completed')}
                  >
                    Completed
                  </TabbedContent.Tab>
                </TabbedContent.Tabs>
              </div>
            </SecondaryNav.Header>
          </SecondaryNav.Root>
          <TabbedContent.Content tabId="all" key="all">
            <ActionsPageTab
              actions={actions.forUsers(userId)}
              focusSkillIds={focusSkillIds}
              source="actions-page"
              editable={true}
              suggestAction={currentUserSelected}
            />
          </TabbedContent.Content>
          <TabbedContent.Content tabId="open" key="open">
            <ActionsPageTab
              actions={actions
                .forUsers(userId)
                .filter((action) => !action.completed)}
              focusSkillIds={focusSkillIds}
              source="actions-page"
              editable={true}
              suggestAction={currentUserSelected}
            />
          </TabbedContent.Content>
          <TabbedContent.Content tabId="completed" key="completed">
            <ActionsPageTab
              actions={actions
                .forUsers(userId)
                .filter((action) => action.completed)}
              focusSkillIds={focusSkillIds}
              source="actions-page"
              editable={false}
            />
          </TabbedContent.Content>
        </TabbedContent.Root>
      )}
    </>
  )
})
