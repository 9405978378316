import { AnimatePresence, motion } from 'framer-motion'
import { Button } from 'src/design-system'
import { CaretDown, CaretUp } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { HtmlContent } from 'components/atoms/editor/html-content'
import { Outcome } from 'store/modules/outcomes'
import { SkillLevel } from 'store/modules/skill-levels'

type SkillLevelSectionProps = {
  skillLevel: SkillLevel
  outcomes: Outcome[]
}

export const SkillLevelSection = observer((props: SkillLevelSectionProps) => {
  const { skillLevel, outcomes } = props

  const [showOutcomes, setShowOutcomes] = React.useState(false)

  const Icon = showOutcomes ? CaretUp : CaretDown

  const text = showOutcomes ? 'Hide' : 'View'

  return (
    <AnimatePresence>
      <div>
        <HtmlContent className="my-4 w-full">{skillLevel.name}</HtmlContent>
        {outcomes.length > 0 && (
          <>
            <Button
              className="bg-transparent border-0 flex focus:shadow-none items-center mb-3 p-0 text-theme-40 text-xs"
              onClick={() => setShowOutcomes(!showOutcomes)}
            >
              {text} {outcomes.length} examples
              <Icon className="h-4 w-4" />
            </Button>
            {showOutcomes && (
              <motion.div
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                initial={{ height: 0, opacity: 0 }}
                transition={{ ease: 'easeInOut', duration: 0.2 }}
              >
                <ul className="pl-4">
                  {outcomes.map((outcome) => (
                    <li className="mb-2" key={outcome.id}>
                      <HtmlContent>{outcome.content}</HtmlContent>
                    </li>
                  ))}
                </ul>
              </motion.div>
            )}
          </>
        )}
      </div>
    </AnimatePresence>
  )
})
