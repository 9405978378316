import * as React from 'react'
import { FocusSkillCard } from './focus-skill-card'
import { FocusSkillWin } from '../../contracts/win'
import { FrameworksSkill } from '../../contracts/frameworks-skill'
import { Skill } from '../../contracts/skill'
import { useFocusSkillsRowVm } from './use-focus-skills-row-vm'
import { observer } from 'mobx-react-lite'

type FocusSkillsRowProps = {
  frameworksSkills: FrameworksSkill[]
  initialFocusSkills: Skill[]
  wins: FocusSkillWin[]
}

export const FocusSkillsRow: React.VFC<FocusSkillsRowProps> = observer(
  (props) => {
    const { frameworksSkills, initialFocusSkills, wins } = props

    const vm = useFocusSkillsRowVm({
      frameworksSkills,
      initialFocusSkills,
      wins,
    })

    React.useEffect(() => {
      const addFocusSkill = ((event: CustomEvent) => {
        vm.addSkillToFocusSkills(event.detail.id)
      }) as EventListener

      const removeFocusSkill = ((event: CustomEvent) => {
        vm.removeSkillFromFocusSkills(event.detail.id)
      }) as EventListener

      document.addEventListener('focusskill:added', addFocusSkill)
      document.addEventListener('focusskill:removed', removeFocusSkill)

      return () => {
        document.removeEventListener('focusskill:added', addFocusSkill)
        document.removeEventListener('focusskill:removed', removeFocusSkill)
      }
    }, [vm])

    if (!vm.showFocusSkillsRow) return null

    return (
      <div>
        <h3 className="mr-2 mb-4">
          Focus Skills{' '}
          <span className="text-gray-300">
            {vm.focusSkillCount}/{vm.maxNumberOfFocusSkills}
          </span>
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {vm.showFocusSkills &&
            vm.focusSkills.map((focusSkill) => (
              <FocusSkillCard key={focusSkill.id} skill={focusSkill} vm={vm} />
            ))}
          {vm.showSuggestedSkills &&
            vm.suggestedSkills.map((suggestedSkill) => (
              <FocusSkillCard
                key={suggestedSkill.id}
                skill={suggestedSkill}
                vm={vm}
              />
            ))}
        </div>
      </div>
    )
  }
)
