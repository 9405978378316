import { z } from 'zod'
import { skillSchema } from './skill'
import { userSchema } from './user'
import { reactionSchema } from './reaction'
import { winCategorySchema } from './win-category'

export const winSchema = z.object({
  id: z.string(),
  content: z.string(),
  skills: z.array(skillSchema),
  winners: z.array(userSchema),
  reactions: z.array(reactionSchema),
  winCategory: z.optional(winCategorySchema).nullable(),
})

export const winsSchema = z.array(winSchema)

export type Win = z.infer<typeof winSchema>

export const focusSkillWinSchema = z.object({
  id: z.string(),
  skills: z.array(skillSchema),
})

export const focusSkillWinsSchema = z.array(focusSkillWinSchema)

export type FocusSkillWin = z.infer<typeof focusSkillWinSchema>
