import { CircleHalf, Star } from '@phosphor-icons/react'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionStatusIcon, FocusSkillCardVm } from './focus-skill-card-vm'
import { CheckinStatusLabel } from 'components/checkin-status-label'
import { isNumber } from '../../utils/number'
import { openModal } from '../../utils/open-modal'
import { UserSkill } from 'store/modules/user_skills'
import { useStore } from 'store/context'

export type FocusSkillCardProps = {
  userSkill: UserSkill
}

export const FocusSkillCard = observer((props: FocusSkillCardProps) => {
  const { userSkill } = props
  const user = userSkill.user
  const skill = userSkill.skill
  const { actions, wins } = useStore()

  const [winCount, setWinCount] = React.useState(0)

  const fetchWins = React.useCallback(async () => {
    const { meta } = await wins.fetchAll({
      filter: {
        skill_id: skill.id,
        win_category_title: 'Win',
        winner_id: user.id,
      },
      page: { size: 0 },
    })

    if (meta?.total && isNumber(meta.total)) setWinCount(meta.total)
  }, [skill, user, wins])

  React.useEffect(() => {
    fetchWins()
  }, [fetchWins])

  const actionsForSkillAndUser = actions.forSkillAndUser(skill.id, user.id)

  const vm = React.useMemo(() => {
    return new FocusSkillCardVm(actionsForSkillAndUser, userSkill, winCount)
  }, [actionsForSkillAndUser, userSkill, winCount])

  const icons: Record<ActionStatusIcon, React.ReactNode> = {
    fire: '🔥',
    'half-circle': <CircleHalf className="h-4 w-4" weight="fill" />,
  }

  const onClick = () => {
    openModal(
      `/users/${user.id}/wins/received?filterrific[skills_filter]=${skill.id}&modal=true`
    )
  }

  return (
    <article className="card focus-skill-card">
      <div className="p-4">
        <Star aria-hidden className="text-theme-50" size={20} weight="fill" />
        <p className="mb-0 mt-4 text-gray-600 text-xs">{vm.cardSubtitle}</p>
        <div className="flex w-full whitespace-nowrap">
          <h5 className="font-bold inline text-base text-gray-900 truncate">
            {skill.name}
          </h5>
          {userSkill.positionRequirementLevel && (
            <p className="inline ml-1 text-base text-gray-600">
              L{userSkill.positionRequirementLevel}
            </p>
          )}
        </div>
        <CheckinStatusLabel status={userSkill.status} />
        {winCount > 0 && (
          <button
            className="hover:text-theme-30 h-[38px] mt-4 text-theme-40 text-xs"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onClick()
            }}
            type="button"
          >
            {vm.winCountText}
          </button>
        )}
        {winCount === 0 && (
          <p className="flex h-[38px] items-center mb-0 mt-4 text-gray-600 text-xs">
            {vm.winCountText}
          </p>
        )}
      </div>
      <div className="border-b-0 border-gray-100 border-l-0 border-r-0 border-solid border-t cursor-default flex h-full items-center justify-between px-4 py-3 rounded-bl rounded-br text-theme-40">
        <div className="flex gap-1 items-center text-xs">
          {icons[vm.actionStatusIconType]}
          <div className="text-gray-900">{vm.actionStatus}</div>
          <div className="text-gray-600">complete</div>
        </div>
      </div>
    </article>
  )
})
