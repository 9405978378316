import { CircleHalf, Plus, Shuffle } from '@phosphor-icons/react'
import { Button, Link } from 'src/design-system'
import * as React from 'react'
import cn from 'classnames'
import { ActionStatusIcon, FocusSkillsRowVm } from './focus-skills-row-vm'
import { CheckinSkillLevelLabel } from 'components/checkin-skill-level-label'
import { Skill } from '../../contracts/skill'
import { useProgress } from 'components/progress-provider'
import { FocusSkillStar } from 'components/focus-skill-star'
import { useStore } from 'store/context'
import { observer } from 'mobx-react-lite'
import { CreateFormModalButton } from 'components/create-form-modal'

export type FocusSkillCardProps = {
  skill: Skill
  vm: FocusSkillsRowVm
}

export const FocusSkillCard: React.VFC<FocusSkillCardProps> = observer(
  (props) => {
    const { skill, vm } = props
    const { userSkills, currentUser } = useStore()

    const { source } = useProgress()

    const userSkill =
      currentUser && userSkills.forSkillAndUser(skill.id, currentUser.id)

    if (!userSkill) return null
    const selected = userSkill.focused

    const icons: Record<ActionStatusIcon, React.ReactNode> = {
      fire: '🔥',
      'half-circle': (
        <CircleHalf
          className={cn(
            'h-4 w-4',
            selected ? 'text-theme-40' : 'text-gray-600'
          )}
          weight="fill"
        />
      ),
    }

    const cardSource = `${source}${
      selected ? '' : '-suggested'
    }-focus-skill-card`

    return (
      <article className="card clickable focus-skill-card">
        <Link
          className="items-start no-underline p-4"
          href={`/users/skills/${skill.slug}`}
        >
          <div className="flex justify-between">
            {userSkill && (
              <FocusSkillStar
                editable={true}
                size={20}
                source={cardSource}
                userSkill={userSkill}
              />
            )}
            {!selected && (
              <button
                className="flex items-center text-gray-600 text-xs"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  vm.shuffleSuggestedSkill(skill)
                }}
                type="button"
              >
                <Shuffle aria-hidden className="h-4 mr-1 w-4" weight="bold" />
                Shuffle
              </button>
            )}
          </div>
          <p className="mb-0 mt-4 text-gray-600 text-xs">
            {vm.cardSubtitle(selected, skill)}
          </p>
          <div className="flex w-full whitespace-nowrap">
            <h5
              className={cn(
                'font-bold inline text-base truncate',
                selected ? 'text-gray-900' : 'text-gray-600'
              )}
            >
              {skill.name}
            </h5>
            {vm.skillRequirementLevel(skill) && (
              <p className="inline ml-1 text-base text-gray-600">
                L{vm.skillRequirementLevel(skill)}
              </p>
            )}
          </div>
          <CheckinSkillLevelLabel
            level={vm.checkinSkillLevel(skill)}
            requiredLevel={vm.skillRequirementLevel(skill)}
            suggested={!selected}
          />
          {vm.showCardWinLink(selected, skill) && (
            <button
              className="hover:text-theme-30 h-[36px] mt-4 text-theme-40 text-xs"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                vm.openWinModal(skill)
              }}
              type="button"
            >
              {vm.cardWinCount(skill)}
            </button>
          )}
          {vm.showCardWinText(selected, skill) && (
            <p className="flex h-[36px] items-center mb-0 mt-4 text-gray-600 text-xs">
              {vm.cardWinCount(skill)}
            </p>
          )}
          {!selected && (
            <Button
              className="justify-center max-w-none mt-4 text-sm text-white w-full"
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault()
                vm.onClickSelectSkill(userSkill, cardSource)
              }}
            >
              Select skill
            </Button>
          )}
        </Link>
        <div className="border-b-0 border-gray-100 border-l-0 border-r-0 border-solid border-t cursor-default flex h-full items-center justify-between px-4 py-3 rounded-bl rounded-br">
          {vm.showAddAction(skill) && (
            <CreateFormModalButton
              className={cn(
                'text-xs bg-white',
                selected ? 'text-theme-40' : 'text-gray-600'
              )}
              user={userSkill.user}
              source={cardSource}
              initialSkillIds={[skill.id]}
              tabs={['action']}
              label="Add Action"
            />
          )}
          {!vm.showAddAction(skill) && (
            <div className="flex gap-1 items-center text-xs">
              {icons[vm.actionStatusIconType(skill)]}
              <div className={cn(selected ? 'text-gray-900' : 'text-gray-600')}>
                {vm.completedActionStatus(skill)}
              </div>
              <div className="text-gray-600">complete</div>
            </div>
          )}
          <CreateFormModalButton
            className="leading-4 bg-white"
            user={userSkill.user}
            source={cardSource}
            initialSkillIds={[skill.id]}
            tabs={['action']}
            title="Add Action"
            label={
              <Plus
                aria-hidden
                className={cn(
                  'h-4 w-4',
                  selected ? 'text-theme-40' : 'text-gray-600'
                )}
                weight="bold"
              />
            }
          />
        </div>
      </article>
    )
  }
)
