import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionsEmptyState } from 'components/actions-empty-state'
import { ActionsTable } from 'components/actions-table'
import { CHECKIN_ACTIONS_SECTION_SOURCE } from './utils'
import { CheckinActionsSectionVm } from './checkin-actions-section-vm'
import { useStore } from 'store/context'
import { CreateFormModalButton } from 'components/create-form-modal'
import { Plus } from '@phosphor-icons/react'

type CheckinActionsSectionProps = {
  previousCheckinCreationDate?: string
  userId: string
  userName: string
  checkinId: string
}

export const CheckinActionsSection = observer(
  (props: CheckinActionsSectionProps) => {
    const { previousCheckinCreationDate, userId, userName, checkinId } = props

    const { actions, currentUser, userSkills } = useStore()

    const fetchActions = React.useCallback(async () => {
      await actions.fetchForUser(userId)
    }, [actions, userId])

    const fetchUserSkills = React.useCallback(async () => {
      await userSkills.fetchForUser(userId)
    }, [userId, userSkills])

    React.useEffect(() => {
      fetchUserSkills()
    }, [fetchUserSkills])

    React.useEffect(() => {
      fetchActions()
    }, [fetchActions])

    const actionsForUser = previousCheckinCreationDate
      ? actions.createdSinceDateTime(new Date(previousCheckinCreationDate), {
          userIds: [userId],
        })
      : actions.forUsers([userId])

    const vm = React.useMemo(() => {
      return new CheckinActionsSectionVm(
        actionsForUser,
        currentUser,
        userId,
        userName
      )
    }, [actionsForUser, currentUser, userId, userName])

    const { users } = useStore()
    const user = userId && users.byId(userId)

    if (!user) return null

    return (
      <>
        {actionsForUser.length > 0 && (
          <>
            <div className="flex items-center justify-between pb-1">
              <h3 className="text-base">
                Actions{' '}
                <span className="text-gray-300">{vm.incompleteCount}</span>
              </h3>
              <CreateFormModalButton
                title="Add action"
                initialUserIds={[user.id]}
                user={user}
                createdFromId={checkinId}
                createdFromType="Checkin"
                source={CHECKIN_ACTIONS_SECTION_SOURCE}
                className="flex items-center justify-center bg-white"
                label={<Plus className="h-4 text-theme-50 w-4" weight="bold" />}
                tabs={['action']}
              />
            </div>
            <p className="text-gray-600 text-xs">{vm.subtitle}</p>
            <ActionsTable
              actions={actionsForUser}
              editable
              focusSkillIds={userSkills.focusedSkillIdsForUser(userId)}
              skillsCharacterLimit={30}
              source={CHECKIN_ACTIONS_SECTION_SOURCE}
            />
            <CreateFormModalButton
              className="btn max-w-none w-full"
              source={CHECKIN_ACTIONS_SECTION_SOURCE}
              user={user}
              variant="outline"
              label="Add action"
              tabs={['action']}
              initialUserIds={[user.id]}
              createdFromId={checkinId}
              createdFromType="Checkin"
            />
          </>
        )}
        {actionsForUser.length === 0 && (
          <ActionsEmptyState
            source={CHECKIN_ACTIONS_SECTION_SOURCE}
            user={user}
            variant="sidebar"
          />
        )}
      </>
    )
  }
)
