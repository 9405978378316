import { Button, Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { CreateFormProps } from 'components/create-form'
import { SOURCE } from '../utils'
import { store } from 'store/index'
import { User } from 'store/modules/users'
import { AiFocusSkillActionsVm } from 'components/ai/content/ai-focus-skill-actions-vm'
import { useModalContext } from 'src/design-system'
import { UserSkill } from 'store/modules/user_skills'
import { OverflowMenu } from 'src/design-system'
import { LoadingContent } from 'components/loading-content'
import { Shuffle, Sparkle } from '@phosphor-icons/react'
import ProgressionAvatar from '../../../../images/progression-avatar.svg'

type SuggestedActionProps = {
  user: User
  userSkills: UserSkill[]
  userSkill?: UserSkill
  variant?: 'default' | 'actions-page'
}

export const SuggestedAction = observer((props: SuggestedActionProps) => {
  const { userSkill: selectedSkill, userSkills, user, variant } = props

  const { openModal } = useModalContext()

  const [action, setAction] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const aiVm = React.useMemo(() => {
    return new AiFocusSkillActionsVm(`${SOURCE}-actions`)
  }, [])

  const userSkill =
    selectedSkill || userSkills[Math.floor(Math.random() * userSkills.length)]

  const inputs = {
    skillId: userSkill.skill.id,
    positionId: user.position?.id,
    level: userSkill.positionRequirementLevel,
  }

  const startGeneration = async (cache = false) => {
    setLoading(true)
    const taskId = await aiVm.onSubmit({ ...inputs, cache: cache })

    if (!taskId) {
      setLoading(false)
      return
    }

    if (taskId) {
      await store.asyncTasks.byId(taskId)?.pollOne()
      const task = store.asyncTasks.byId(taskId)
      if (task?.isSuccess) {
        const action =
          task.output?.focusSkillActions?.output?.extracted[0]?.items[0]
            ?.action || ''
        await setAction(action)
        setLoading(false)
        return
      }
    }
  }

  React.useEffect(() => {
    startGeneration()
  }, [])

  const onEdit = React.useCallback(() => {
    if (!store.currentUser) return
    openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
      initialSkillIds: [userSkill.skill.id],
      initialContent: action,
      source: `${SOURCE}-ai-suggested-actions`,
      user: store.currentUser,
      tabs: ['action'],
    })
  }, [action, openModal, userSkill.skill.id])

  if (!loading && !action) return null

  return (
    <LoadingContent className="py-5" loading={loading}>
      {variant === 'actions-page' ? (
        <ActionsPageTemplate
          action={action}
          userSkill={userSkill}
          onEdit={onEdit}
          startGeneration={startGeneration}
        />
      ) : (
        <DefaultTemplate
          action={action}
          userSkill={userSkill}
          onEdit={onEdit}
          startGeneration={startGeneration}
        />
      )}
    </LoadingContent>
  )
})

type DefaultTemplateProps = {
  action: string
  userSkill: UserSkill
  onEdit: () => void
  startGeneration: (cache: boolean) => void
}

const DefaultTemplate = (props: DefaultTemplateProps) => {
  const { action, userSkill, onEdit, startGeneration } = props

  return (
    <div className="m-0 w-full flex flex-row justify-between self-start gap-2">
      <div className="flex flex-col gap-2">
        {action && <div className="flex flex-col gap-2">{action}</div>}
        <Link
          className="p-0 text-xs text-pink-700"
          href={`/users/skills/${userSkill.skill.slug}`}
          onClick={(e) => e.stopPropagation()}
          underline={false}
        >
          <span>{userSkill.skill.name}</span>
        </Link>
      </div>
      <div className="flex flex-col gap-2 items-end">
        <>
          <Link
            className="text-pink-700 hover:text-pink-600 cursor-pointer"
            underline={false}
            onClick={onEdit}
          >
            Add
          </Link>
          <OverflowMenu.Root
            className="hover:text-pink-700"
            contentClassName="min-w-[160px]"
            right
          >
            <Button
              className="text-pink-700 bg-white p-3"
              onClick={() => startGeneration(false)}
            >
              Suggest new action
            </Button>
          </OverflowMenu.Root>
        </>
      </div>
    </div>
  )
}

const ActionsPageTemplate = (props: DefaultTemplateProps) => {
  const { action, userSkill, onEdit, startGeneration } = props
  return (
    <>
      <div className="flex flex-row mb-2">
        <div className="flex-1 flex flex-row">
          <img alt="Progression" src={ProgressionAvatar} />
          <div className="text-sm text-gray-600 ml-2 leading-8">
            Suggested action
          </div>
        </div>
        <div className="flex flex-row gap-2 items-end">
          <Button
            className="bg-white text-gray-600"
            onClick={() => startGeneration(false)}
          >
            <Shuffle className="w-4 h-4" /> Shuffle
          </Button>
          <Button
            onClick={onEdit}
            className="btn-sm btn-outline text-pink-600 border-pink-600"
            variant="outline"
          >
            Add
          </Button>
        </div>
      </div>
      <div className="m-0 w-full flex flex-row justify-between self-start gap-2">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-2">
            <div>
              <Sparkle
                className="text-pink-600 w-4 h-4"
                size={16}
                weight="fill"
              />
            </div>
            {action}
          </div>
          <Link
            className="p-0 text-xs text-theme-500 ml-6"
            href={`/users/skills/${userSkill.skill.slug}`}
            onClick={(e) => e.stopPropagation()}
            underline={false}
          >
            <span>{userSkill.skill.name}</span>
          </Link>
        </div>
      </div>
    </>
  )
}
