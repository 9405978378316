import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Action } from 'store/modules/actions'
import { ActionPageItem } from './action-page-item'
import { CreateFormModalButton } from 'components/create-form-modal'
import { store } from 'store/index'
import { SuggestedAction } from 'pages/growth-profile-page/actions-section/suggested-action'

type ActionsPageTabProps = {
  actions: Action[]
  characterLimit?: number
  editable?: boolean
  focusSkillIds: string[]
  skillsCharacterLimit?: number
  source: string
  suggestAction?: boolean
}

export const ActionsPageTab = observer((props: ActionsPageTabProps) => {
  const {
    actions,
    characterLimit,
    editable = false,
    focusSkillIds,
    skillsCharacterLimit,
    source,
    suggestAction,
  } = props

  const { nonNullCurrentUser } = store
  const userSkills = store.userSkills.forUser(nonNullCurrentUser.id)

  return (
    <div className="mt-16 max-w-[880px] container mx-auto">
      <CreateFormModalButton
        title={'Add Action'}
        user={nonNullCurrentUser}
        source={'activity_feed'}
        className="btn btn-brand btn-sm mb-4"
        label={'Add Action'}
        initialTabId={'action'}
      />

      <ul className="list-none p-0 flex flex-col">
        {actions.map((action) => (
          <ActionPageItem
            action={action}
            characterLimit={characterLimit}
            editable={editable}
            focusSkillIds={focusSkillIds}
            // Using the action's id as the key doesn't trigger a store update so workaround for now
            key={`${action.id}-${action.updatedAt.toISOString()}`}
            skillsCharacterLimit={skillsCharacterLimit}
            source={source}
          />
        ))}
        {suggestAction && !!userSkills.length && (
          <li className="border p-4 rounded-sm mb-4 flex flex-col">
            <SuggestedAction
              user={nonNullCurrentUser}
              userSkills={userSkills}
              variant="actions-page"
            />
          </li>
        )}
      </ul>
    </div>
  )
})
