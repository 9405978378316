import { Action } from 'store/modules/actions'
import { UserSkill } from 'store/modules/user_skills'

export type ActionStatusIcon = 'fire' | 'half-circle'

export class FocusSkillCardVm {
  constructor(
    private actions: Action[],
    private userSkill: UserSkill,
    private winCount: number
  ) {}

  get actionStatus() {
    return `${this.completedActionCount}/${this.actionCount}`
  }

  get actionStatusIconType(): ActionStatusIcon {
    return this.actionsAllCompleted ? 'fire' : 'half-circle'
  }

  get cardSubtitle() {
    return this.userSkill.categoryName || 'Uncategorised'
  }

  get winCountText() {
    switch (this.winCount) {
      case 0:
        return 'No Wins'
      case 1:
        return '1 Win'
      default:
        return `${this.winCount} Wins`
    }
  }

  private get actionCount() {
    return this.actions.length
  }

  private get actionsAllCompleted() {
    return (
      this.actionCount !== 0 && this.actionCount === this.completedActionCount
    )
  }

  private get completedActionCount() {
    return this.actions.filter((action) => action.completed).length
  }
}
